/* eslint eqeqeq: 0 */
import moment from 'moment';
import * as momentTZ from 'moment-timezone';
import jstz from 'jstz';
import {
  chain,
  find,
  flattenDeep,
  get,
  isEmpty,
  isPlainObject,
  startCase,
  values,
} from 'lodash';
import { stateNamesMap } from '../constants';
import { getUniversalCookies } from './cookies';
import config from '../../config';
import { WebConfig } from '../../models/mpConfiguration/webConfig';

// American TZ's that don't follow DST
const DSTExceptions = [
  'America/Phoenix',
  'US/Arizona',
  'US/Hawaii',
];
export const isDST = userTimeZone => {
  if (!userTimeZone) userTimeZone = jstz.determine().name();
  const isTimezoneDST = (DSTExceptions.includes(userTimeZone)) 
    ? momentTZ.tz('America/Los_Angeles').isDST()
    : momentTZ.tz(userTimeZone).isDST();
  return isTimezoneDST;
};

const epochNow_TEST =
  momentTZ.tz().unix() * 1000 - (isDST() ? 25200000 : 28800000); // keep;

export const logKeysAndTokens = (statuses = {}) => {
  console.info(
    Object.entries(statuses)
      .filter(([key, value]) => value)
      .map(([key, value]) => `${value ? `✅` : ``} ${key}`)
      .concat('>>Auth')
      .reverse()
      .join('\n')
  );
};

export const refreshApiKey = async (refreshToken, callback) => {
  if (!refreshToken) return null;

  const apiRoot = getConfig('apiRoot');
  const mpId = getConfig('marketplaceId');
  const url = `${apiRoot}login?mpid=${mpId}&refreshToken=${refreshToken}`;
  sessionStorage.setItem('refreshingApiKey', true);

  return await fetch(url, { mode: 'cors' })
    .then(response => response.json().then(json => ({ json, response })))
    .then(({ json, response }) => {
      if (response.ok && json.wsStatus === 'Success') {
        const { apiKey, expiresOn } = json;
        const expires = moment(expiresOn).toDate();
        sessionStorage.removeItem('refreshingApiKey');
        const cookies = getUniversalCookies();
        cookies.remove('apiKey');
        cookies.set('apiKey', apiKey, { expires, path: '/' });
        if (callback) callback(json);
        // logKeysAndTokens({ refreshToken, apiKeyFromRefreshToken: apiKey });
        return apiKey;
      } else {
        throw new Error(json.wsMessage);
      }
    })
    .catch(err => {
      console.error(err || 'Error validating login credentials...');
      return null;
    });
};

export const loadApiKey = async () => {
  const cookies = getUniversalCookies();

  const apiKeyFromUrl = parseValue(getParamFromUrl('ticket'));
  const refreshTokenFromUrl = parseValue(getParamFromUrl('refreshToken'));

  if (refreshTokenFromUrl) {
    // if no api key (aka ticket) try fetching one
    const apiKey = apiKeyFromUrl || (await refreshApiKey(refreshTokenFromUrl));

    if (apiKey) {
      cookies.set('apiKey', apiKey, {
        path: '/',
        expires: moment().add(14, 'minutes').toDate(),
      });
      cookies.set('refreshToken', refreshTokenFromUrl, {
        path: '/',
        expires: moment()
          .add(168, 'hours') // 7 days
          .toDate(),
      });

      window.history.replaceState(null, '', window.location.origin); // strips out all params

      return apiKey;
    }
  }

  // typical navigation auth
  const apiKeyFromCookie = parseValue(cookies.get('apiKey'));
  const refreshTokenFromCookie = parseValue(cookies.get('refreshToken'));

  if (refreshTokenFromCookie) {
    // if no api key it has probably expired, just fetch a new one
    const apiKey =
      apiKeyFromCookie || (await refreshApiKey(refreshTokenFromCookie));

    if (apiKey) {
      return apiKey;
    }
  }

  return null;
};

export const handleSimulcastAuth = () => {
  const cookies = getUniversalCookies();
  const apiKeyFromCookie = cookies.get('apiKey');
  const refreshTokenFromCookie = cookies.get('refreshToken');
  const apiRoot = getConfig('apiRoot');
  const mpId = getConfig('marketplaceId');

  if (!apiKeyFromCookie && !refreshTokenFromCookie) {
    window.history.replaceState(null, '', '/login');
  } else if (!apiKeyFromCookie && refreshTokenFromCookie) {
    refreshApiKey(apiRoot, mpId, refreshTokenFromCookie, handleSimulcastLaunch);
  } else if (apiKeyFromCookie && refreshTokenFromCookie) {
    // check status of apiKey
    const url =
      `${apiRoot}` +
      `simulcast-status` +
      `?apiKey=${apiKeyFromCookie}` +
      `&mpId=${mpId}`;

    fetch(url)
      .then(response => {
        if (response.ok) {
          handleSimulcastLaunch(apiKeyFromCookie);
        } else {
          refreshApiKey(
            apiRoot,
            mpId,
            refreshTokenFromCookie,
            handleSimulcastLaunch
          );
        }
      })
      .catch(() => {
        alert('Badge request error, please try again.');
      });
  }
};

/** Returns value of the given config 'field' (_parameter_)
 * 
 * @param {keyof WebConfig } parameter customer config field
 * 
 * @returns {any}
 */
export const getConfig = parameter => {
  // shortcut
  if (parameter === 'marketplaceId') {
    const marketplaceId = localStorage.getItem('marketplaceId');
    if (marketplaceId) return marketplaceId;
  }

  const cookies = getUniversalCookies();

  const conf =
    JSON.parse(localStorage.getItem('config')) ||
    cookies.get('apiKey') ||
    config['default'];

  return conf[parameter];
};
// 2024.10.22 - replaced with /api/configurations endpoint
/**
 * 
 * @param  {...keyof WebConfig|string} parameters 
 * @returns 
 */
export const getConfigs = (...parameters) => {
  const cookies = getUniversalCookies();
  return parameters.map(parameter => {
    // shortcut
    if (parameter === 'marketplaceId') {
      const marketplaceId = localStorage.getItem('marketplaceId');
      if (marketplaceId) return marketplaceId;
    }
    const conf =
      JSON.parse(localStorage.getItem('config')) ||
      cookies.get('apiKey') ||
      config['default'];

    return conf[parameter];
  });
};

export const getEnv = () => {
  return process.env['MY_NODE'];
};

export const parseValue = value => {
  if (value === 'undefined') return undefined;
  if (value === 'null') return null;
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
};

export const getParamFromUrl = (name, url) => {
  const queryString = !url
    ? window.location.search
    : url.includes('//')
    ? url.split('?').pop()
    : url;
  const params = new URLSearchParams(queryString);
  const param = params.get(name);
  return param;
};

export const removeParamFromURL = (param = '', url = '') => {
  const [path, searchParams] = url.split('?');
  const newSearchParams = searchParams
    ?.split('&')
    .filter(p => !(p === param || p.startsWith(`${param}=`)))
    .join('&');
  return newSearchParams ? `${path}?${newSearchParams}` : path;
};

export const removeParamFromQuery = (param = '', query = '') => {
  // NOTE - will keep any "?" in the query string
  const newSearchParams = query
    ?.split('&')
    .filter(p => !(p === param || p.startsWith(`${param}=`)))
    .join('&');
  return newSearchParams;
};

export const clearAllStorage = () => {
  const cookies = getUniversalCookies();
  cookies.remove('apiKey');
  cookies.remove('refreshToken');
  cookies.remove('userId');
  cookies.remove('marketplaceId');
  cookies.remove('mpId');
  cookies.remove('session');

  localStorage.removeItem('userId');
  localStorage.removeItem('tour');
  localStorage.removeItem('carfaxRedirectPath');
  localStorage.removeItem('marketplaceMapState');
  localStorage.removeItem('orderBuyersAr');
  localStorage.removeItem('orderSellerAr');

  // sessionStorage.clear();
};

export const compose = (...funcs) => {
  if (funcs.length === 0) return arg => arg;
  if (funcs.length === 1) return funcs[0];
  return funcs.reduce(
    (a, b) =>
      (...args) =>
        a(b(...args))
  );
};

export const isJSON = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isEncoded = str => {
  try {
    decodeURIComponent(str);
  } catch (e) {
    return false;
  }

  return str !== decodeURIComponent(str);
};

export const isValidNumber = str => {
  var n = ~~Number(str);
  return String(n) === str && n > 0;
};

export const isValidEmail = input => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return String(input).toLowerCase().match(re);
};

export const isValidPhoneNumber = input => {
  var re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  return re.test(input);
};

export const commafy = (x, round = false) => {
  if (!x && x !== 0) return x;
  let commafied = '';
  x = String(x);
  x = x.split('.');
  let [whole, fractal] = x;
  whole = whole.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (fractal && !round) commafied = whole + '.' + fractal;
  else commafied = whole;
  return commafied;
};

export const commafyCurrency = (x, currency) => {
  if (!x && x !== 0) return x;
  currency = currency || '$';
  let [whole, fraction] = String(x).split('.');
  whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const result = fraction
    ? `${currency}${[whole, fraction].join('.')}`
    : `${currency}${whole}`;
  return result;
};

export const commafyCurrency_NEW = (x, digits = 0, currency = 'USD') => {
  const options = {
    style: 'currency',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    currency,
  };
  const formatter = new Intl.NumberFormat('en-US', options);
  return formatter.format(x);
};

export const findStateAbbreviation = state => {
  const abbreviation = stateNamesMap.get(String(state).toLowerCase().trim());
  if (abbreviation) return abbreviation.toUpperCase();
  return state;
};

export const makeTitle = str => {
  if (!str || typeof str !== 'string') return str;
  const split = str.replace(/([a-z])([A-Z])/g, '$1 $2');
  return startCase(split);
};

export const formatPhoneNumber = (phone = '') => {
  if (getConfig('localization') === 'en-uk' && phone.length === 11) {
    var numbers = phone.replace(/\D/g, ''),
      char = { 4: ' ' };
    phone = '';
    for (var i = 0; i < numbers.length; i++) {
      phone += (char[i] || '') + numbers[i];
    }
    return phone;
  } else {
    let formatted = String(phone).replace(/[^\d]/g, '');
    if (formatted.length === 10) {
      formatted = formatted.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    return formatted;
  }
};

const getTimeZoneOffsetByTimeZone = (d, tz) => {
  // TODO: - Fix this code.
  // Passing anything other than UTC is not browser compatible with IE!!!
  // Below line is a temp fix to prevent IE error
  tz = 'UTC';
  var a = d.toLocaleString('ja', { timeZone: tz }).split(/[/\s:]/);
  //var a =  moment.tz(d, tz).format('YYYY/MM/DD hh:mm:ss').split(/[\/\s:]/);
  a[1]--;
  var t1 = Date.UTC.apply(null, a);
  var t2 = new Date(d).setMilliseconds(0);
  return (t2 - t1) / 60 / 1000;
};

export const getUserTimeOffset = () => {
  var serverOffset = getTimeZoneOffsetByTimeZone(
    new Date(),
    'America/Los_Angeles'
  );
  var today = new Date();
  var offsetFromServer = serverOffset / 60 - today.getTimezoneOffset() / 60;
  return offsetFromServer;
};

export const removeProtocol = url => {
  return url.replace(/^https?:/i, '');
};

export const stripHtml = text => {
  if (text !== '' && text) {
    return text.replace(/<(?:.|\n)*?>/gm, '');
  }
};

export const shiftTimeZone = time => {
  return moment(time).add(getUserTimeOffset(), 'hours');
};

export const checkBuyNowPrice = (outrightPrice, feedPrice) => {
  if (outrightPrice && outrightPrice > 0) {
    return outrightPrice;
  } else if (feedPrice && feedPrice > 0) {
    return feedPrice;
  } else {
    return 0;
  }
};

export const makeReadableDate = (
  sourceTime,
  format = 'ddd M/D/YY h:mm a z'
) => {
  if (!sourceTime) return null;
  const utcOffset = momentTZ.parseZone(sourceTime).utcOffset();
  if (!utcOffset) sourceTime += '-07:00'; // if not indicated assume pacific time (server time)
  const userTimeZone = jstz.determine().name();
  return momentTZ(sourceTime).tz(userTimeZone).format(format);
};

export const titleCase = input => {
  return typeof input === 'string'
    ? input
        .split(' ')
        .map(word =>
          word
            .split('')
            .map((char, i) => {
              return i === 0 || word[i - 1] === '-'
                ? char.toUpperCase()
                : char.toLowerCase();
            })
            .join('')
        )
        .join(' ')
    : input;
};

export const filterLogicStartsWith = (filter, row, column) => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id])
        .toLowerCase()
        .replace(/[$,]/gi, '')
        .startsWith(filter.value.toLowerCase())
    : true;
};

export const filterLogicIncludes = (filter, row, column) => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id])
        .toLowerCase()
        .replace(/[$,]/gi, '')
        .includes(filter.value.toLowerCase())
    : true;
};

export const setColumnWidth = (
  header,
  accessorOrId,
  data,
  minWidth = 60,
  maxWidth = 2000
) => {
  if (minWidth === maxWidth) return minWidth; // to set width directly
  const separator = /[\s-]+/;
  const headerWords = String(header).split(separator);
  const dataWords = flattenDeep(
    values(data).map(d => `${d[accessorOrId]}`.split(separator))
  );
  const allWords = [...dataWords, ...headerWords];
  const allWordLengths = allWords.map(w => String(w).length);
  const longestWordLength = Math.max(...allWordLengths);
  const spacingFactor = 10;
  const dynamicWidth = longestWordLength * spacingFactor;
  const width = Math.min(Math.max(minWidth, dynamicWidth), maxWidth);
  return width;
};

export const sortDate = (a, b) => moment(a) - moment(b);

export const handleSimulcastLaunch = apiKey => {
  let simulcastWindow = window.open(
    'about:blank',
    1,
    1,
    1,
    0,
    0,
    Math.min(window.screen.width, 1440),
    Math.min(window.screen.height - 100, 900),
    ''
  );

  const url =
    `${getConfig('apiRoot')}` +
    `user-profile` +
    `?apikey=${apiKey}` +
    `&userId=${localStorage.getItem('userId')}` +
    `&mpId=${getConfig('marketplaceId')}`;

  fetch(url)
    .then(response => {
      if (response.status === 200) {
        const windowUrl = getConfig('liveSimulcastUrl') + apiKey + '&Relogin=1';

        simulcastWindow.location.href = windowUrl;
      } else {
        alert('Error getting badge, please try again.');
      }
    })
    .catch(() => {
      alert('Badge request error, please try again.');
    });
};

export const launchWindow = (url = '', windowQuery = '') => {
  if (!windowQuery) {
    const width = Math.min(1024, window.screen.width * 0.85);
    const height = 450;
    const left = window.screen.width / 2 - (width / 2 + 10);
    const top = window.screen.height / 2 - (height / 2 + 50);

    windowQuery = `
    status=no,
    height=${height},
    width=${width},
    resizable=yes,
    left=${left},
    top=${top},
    screenX=${left},
    screenY=${top},
    toolbar=no,
    menubar=no,
    scrollbars=no,
    location=no,
    directories=no`;
  }

  window.open(url, '', windowQuery);
};

export const getCurrentPath = location => {
  let pathname = location.pathname;
  let pathArr = pathname.split('/').reverse();
  return pathArr[0];
};

export const makeTimeText = (timeA, timeB, maxValues = 6) => {
  const duration = momentTZ.duration(timeB - timeA, 'milliseconds');

  const timeBlock = {
    days: duration.days(),
    hrs: duration.hours(),
    min: duration.minutes(),
    sec: duration.seconds(),
  };

  return Object.entries(timeBlock)
    .filter(entry => {
      // filters out zero values except for seconds
      const [label, time] = entry;
      return label === 'sec' || time > 0;
    })
    .slice(0, maxValues) // default is all the non-zeros
    .map((entry, index, arr) => {
      // make singular if value is 1
      let [label, val] = entry;
      if (val === 1 && ['days', 'hrs'].includes(label)) {
        label = label.slice(0, -1);
      }
      return `${val} ${label}`;
    })
    .join(' ');
};

export const determineEventStatus = (epochStart, epochEnd, epochNow) => {
  epochNow = epochNow || momentTZ.tz().unix() * 1000;

  if (!epochStart || !epochEnd) {
    return '';
  } else if (epochNow < epochStart) {
    return 'isBefore';
  } else if (epochNow > epochEnd - 86400000 && epochNow < epochEnd) {
    return 'isInProgress24';
  } else if (epochNow < epochEnd) {
    return 'isInProgress';
  } else {
    return 'isAfter';
  }
};

export const determineCountdownInfo = itemData => {
  if (!itemData) {
    return {};
  }

  let {
    isMPSellingLive,
    listingTypeId,
    statusId,
    listingStatusId,
    epochStartTime,
    epochEndTime,
  } = itemData;

  // elasticsearch uses "listingStatusId", item api uses "statusId"
  if (!statusId && statusId !== 0) {
    statusId = listingStatusId;
  }

  // NOTE: - DEV only for testing timers. Keep.
  if (process.env['MY_NODE'] === 'development' && false) {
    const minToStart = 0.1;
    const minToEnd = 2;

    statusId = 1;
    listingTypeId = 1;
    epochStartTime = epochNow_TEST + minToStart * 60000;
    epochEndTime = epochNow_TEST + minToEnd * 60000;
  }

  if (statusId !== 1 || listingTypeId !== 1) {
    return {};
  }

  /* 2024.11 - TODO: 
    - Is this right? Determine if DST on the client side?
      -> Arizona/Hawaii do not observe DST
      -> Does server adjust to Daylight Savings?
  */
  const epochNow =
    momentTZ.tz().unix() * 1000 - (isDST() ? 25200000 : 28800000); // adjust GMT time to server time (7 hours or 8 hours depending on DST)

  const countdownInfo = {
    eventStatus: determineEventStatus(epochStartTime, epochEndTime, epochNow), // isBefore, isInProgress, isInProgress24, isAfter
    countdownType: '', // subtle, loud
    label: '', // Bidding Starts In, Selling In, Bidding Closes In, Bidding is Closed
    time: '', // readable string
  };

  switch (countdownInfo.eventStatus) {
    case 'isBefore': {
      countdownInfo.countdownType = 'subtle';
      countdownInfo.label = 'Bidding Starts In:';
      countdownInfo.time = makeTimeText(epochNow, epochStartTime, 2);
      break;
    }
    case 'isInProgress24': {
      countdownInfo.countdownType = 'loud';
      countdownInfo.label = isMPSellingLive
        ? 'Selling In:'
        : 'Bidding Closes In:';
      countdownInfo.time = makeTimeText(epochNow, epochEndTime, 2);
      break;
    }
    case 'isInProgress': {
      break;
    }
    case 'isAfter': {
      countdownInfo.countdownType = 'loud';
      countdownInfo.label = 'Bidding is Closed';
      break;
    }

    default:
      break;
  }

  return countdownInfo;
};

export const showOfferButton = itemData => {
  if (!itemData) {
    return null;
  }

  let {
    statusId,
    listingStatusId,
    listingTypeId,
    eventFeatures,
    mpFeatures,
    marketplaceFeatures,
    subItemDetails,
    parentListingId,
  } = itemData;

  // Elasticsearch uses listingStatusId
  if (!statusId && statusId !== 0) {
    statusId = listingStatusId;
  }

  // Elasticsearch uses marketplaceFeatures
  if (!mpFeatures) {
    mpFeatures = marketplaceFeatures;
  }

  const eventFeaturesArr = String(eventFeatures).split(',');
  const isBulkLot = (subItemDetails || []).length || parentListingId > 0;

  const neededConditions =
    statusId === 1 && // at auction
    ![1, 3, 101].includes(listingTypeId) && // NOT proxy auction, live simulcast, or on hold
    eventFeaturesArr.includes('45') && // offers enabled in event settings
    !eventFeaturesArr.includes('17') && // is not a view only event
    !isBulkLot; // is not bulk lot parent or sub

  return neededConditions ? true : false;
};

export const showBidButton = itemData => {
  if (!itemData) {
    return null;
  }

  let { statusId, listingStatusId, listingTypeId, eventFeatures } = itemData;

  // Elasticsearch uses listingStatusId
  if (!statusId && statusId !== 0) {
    statusId = listingStatusId;
  }

  const { eventStatus } = determineCountdownInfo(itemData);
  const eventFeaturesArr = String(eventFeatures).split(',');

  let neededConditions =
    statusId == 1 && // at auction
    [1, 3].includes(listingTypeId) && // proxy auction or live auction
    eventStatus !== 'isAfter' &&
    !eventFeaturesArr.includes('17'); // not View Only Event

  if (
    neededConditions &&
    eventStatus === 'isBefore' &&
    eventFeaturesArr.includes('39') // Disable Bidding Prior to Sale
  ) {
    neededConditions = false;
  }

  return neededConditions ? true : false;
};

/**
 * @typedef {Object} BuyNowItemData
 * @property {number|undefined} statusId
 * @property {number|undefined} listingStatusId
 * @property {number} outrightPrice
 * @property {boolean} isMPSellingLive
 * @property {string} eventFeatures
 */
/** @type {BuyNowItemData} */

/**
 * @param {BuyNowItemData &{[key:string]: any} | undefined} itemData 
 * @returns {boolean}
 */
export const showBuyNowButton = itemData => {
  if (!itemData) {
    return null;
  }

  let {
    statusId,
    listingStatusId,
    outrightPrice,
    isMPSellingLive,
    eventFeatures,
  } = itemData;

  // Elasticsearch uses listingStatusId
  if (!statusId && statusId !== 0) {
    statusId = listingStatusId;
  }

  const neededConditions =
    statusId == 1 && // at auction
    outrightPrice > 0 && // has a buy price
    !isMPSellingLive && // is not selling live
    !String(eventFeatures).split(',').includes('17'); // is not a view only event

  return neededConditions ? true : false;
};

/** Determines "Buy Now" (_label, text, etc._) text. 
 *  * 'Upstream' / 'In-Flight' item will display "Reserve Unit" 
 * @param {BuyNowItemData} itemData
 * @param {boolean|undefined} shortLabel _default=`false`_; returns `"Buy" | "Reserve"` instead
 * @returns {"Buy Now" | "Reserve Unit" | "Buy" | "Reserve"}
 */
export const getBuyNowText = (itemData, shortLabel=false) => {
  let buyNowText = "Buy Now";
  const eventFeatures = itemData?.eventFeatures || '';
  if (eventFeatures && typeof eventFeatures === 'string') {
    const isIfSaleListing = (eventFeatures.split(',') || []).includes('53');
    buyNowText = isIfSaleListing ? "Reserve Unit" : buyNowText;
  }
  return shortLabel ? buyNowText.split(" ")[0] : buyNowText;
}

export const camelizeStr = (s = '') => {
  return s.replace(/-./g, x => x.toUpperCase()[1]);
};

export const camelizeObj = (obj = {}) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [camelizeStr(key), value])
  );
};

export const getTemplate = (template = {}, path = '') => {
  let value = get(template, path);
  if (!value) {
    if (isEmpty(template)) {
      console.error('Error', `Empty template for path "${path}"`);
    } else if (value === undefined || value === null) {
      console.warn('Warn', `No value for "${path}" in template`);
    } else {
      console.warn('Warn', `Empty value for "${path}" in template`);
    }

    if (path.includes('.color')) value = '#FFF';
    else value = '#6D6D6D';
  }
  return value;
};

export const makeTemplate = (cssConfig = {}, loading = false) => {
  // this function returns the template the marketplace will use to determine many of it's colors.
  // the function takes either the awgCSSConfig or a default config and maps those settings to ui elements.
  // if the awgCSSConfig has *ANY* settings it will be used, otherwise it will use the default settings.
  // loading = true means we don't know yet whether there are awgCSSConfig settings yet so use fallback colors (NOT default colors).
  // this template is available as the redux prop "template" and properties are easily accessed using the "getTemplate" helper
  // it is OK to use customer specific settings here as it would be unweildly to use create the a template map in the configs & might overload the cookie.

  const hasAWGCSSConfigValues = Object.values(cssConfig || {}).some(val => val);

  if (!loading && !hasAWGCSSConfigValues) {
    // create default
    cssConfig = {
      primaryButtonColor: '#5CBE7F',
      secondaryButtonColor: '#27AE60',
      tertiaryButtonColor: '#F39C12',
      primaryLabelColor: '#83BE97',
      secondaryLabelColor: '#83BE97',
      tertiaryLabelColor: '#239E3',
      headlineColor: '#000',
      subtitleColor: '#6D6D6D',
      eventCardHeaderColor: '#848DC3',
      eventCardTextColor: '#848DC3',
      mpCardHeaderColor: '#259DB3',
      mpCardTextColor: '#259DB3',
    };
  }

  // some useful hex colors we like
  const black = '#000';
  const darkBlue = '#286090';
  const darkGray = '#808080';
  const green = '#27ae60';
  const lightBlue = '#5BC0DE';
  const lightGray = '#E0E1E2';
  const medGray = '#6D6D6D';
  const mpSteel = '#6492BD'; // current item card (text)
  const orange = '#f39c12';
  const red = '#c0392b';
  const white = '#FFF';

  // the fallbacks for any missing cssConfig values
  const {
    headlineColor = black,
    subtitleColor = medGray,
    primaryButtonColor = medGray,
    secondaryButtonColor = medGray,
    tertiaryButtonColor = medGray,
    primaryLabelColor = medGray,
    secondaryLabelColor = medGray,
    tertiaryLabelColor = medGray,
    mpCardHeaderColor = medGray,
    mpCardTextColor = white,
    eventCardHeaderColor = medGray,
    eventCardTextColor = white,
  } = cssConfig;

  const mpId = getConfig('marketplaceId');

  return {
    mpId,
    source: hasAWGCSSConfigValues ? 'awg' : 'default',
    signInButton: {
      backgroundColor: mpId === '550' ? black : primaryButtonColor,
      color: white,
    },
    searchAllInventoryButton: {
      backgroundColor:
        mpId === '550' ? secondaryButtonColor : primaryButtonColor,
      color: mpId === '550' ? black : white,
    },
    joinLiveSimulcastButton: {
      backgroundColor:
        mpId === '550' ? secondaryButtonColor : primaryButtonColor,
      color: mpId === '550' ? black : white,
    },
    saveSearchButton: {
      backgroundColor: primaryLabelColor,
      color: white,
    },
    marketplaceCardHeader: {
      backgroundColor: mpCardHeaderColor,
      color: white,
    },
    marketplaceCardCount: {
      color: mpCardTextColor,
    },
    marketplaceCardText: {
      color: mpCardTextColor,
    },
    eventCardHeader: {
      backgroundColor: eventCardHeaderColor,
      color: white,
    },
    eventCardCount: {
      color: eventCardTextColor,
    },
    eventCardText: {
      color: eventCardTextColor,
    },
    itemCardTitle: {
      color: mpSteel,
    },
    itemCardBidButton: {
      backgroundColor: secondaryLabelColor,
      color: white,
    },
    itemCardOfferButton: {
      backgroundColor: secondaryLabelColor,
      color: white,
    },
    itemCardBuyButton: {
      backgroundColor: secondaryLabelColor,
      color: white,
    },
    itemCardWatchlistButton: {
      backgroundColor: darkGray,
      color: white,
    },
    itemCardBidBadge: {
      backgroundColor: secondaryLabelColor,
      color: white,
    },
    itemCardBuyBadge: {
      backgroundColor: primaryLabelColor,
      color: white,
    },
    itemCardLotNumberBadge: {
      backgroundColor: medGray,
      color: white,
    },
    itemCardGradeBadge: {
      backgroundColor: medGray,
      color: white,
    },
    detailsBidButton: {
      backgroundColor: secondaryLabelColor,
      color: white,
    },
    detailsOfferButton: {
      backgroundColor: secondaryLabelColor,
      color: white,
    },
    detailsBuyButton: {
      backgroundColor: secondaryLabelColor,
      color: white,
    },
    detailsWatchlistButton: {
      backgroundColor: darkGray,
      color: white,
    },
    detailsMMRButton: {
      backgroundColor: darkGray,
      color: white,
    },
    detailsRetailButton: {
      backgroundColor: lightGray,
      color: darkGray,
    },
    detailsNotesButton: {
      backgroundColor: tertiaryButtonColor,
      color: white,
    },
    detailsReserveItemButton: {
      backgroundColor: lightGray,
      color: darkGray,
    },
    countdownTimer: {
      backgroundColor: primaryButtonColor,
      color: white,
    },
    homeSectionViewAll: {
      color: tertiaryLabelColor,
    },
    misc: {
      white,
      gray: medGray,
      medGray,
      darkGray,
      lightGray,
      black,
      green,
      red,
      orange,
      lightBlue,
      darkBlue,
    },
  };
};

export const findMileageUnits = item => {
  const units = [item.odometerNotes, item.mileageDesc, item.MileageDesc]
    .filter(item => !!item)
    .some(item => String(item).toUpperCase().includes('KM'))
    ? 'km'
    : 'mi';

  return units;
};

export const makeLiveSimulcastLink = cookies => {
  const mpId = getConfig('marketplaceId');
  const getApiRoot = getConfig('apiRoot');
  const apiRoot = encodeURIComponent(getApiRoot);
  const getLiveSimulcastUrl = getConfig('liveSimulcastUrl');
  const liveSimulcastUrl = encodeURIComponent(getLiveSimulcastUrl);
  const refreshToken = cookies.get('refreshToken');

  let uri =
    `/simulcast/redirect/` +
    `?apiRoot=${apiRoot}` +
    `&liveSimulcastUrl=${liveSimulcastUrl}` +
    `&mpId=${mpId}` +
    `&refreshToken=${refreshToken}`;

  const liveSimulcastType = getConfig('liveSimulcastType');
  const getLiveHyperlaneUrl = getConfig('liveHyperlaneUrl');
  if (liveSimulcastType === 'hyperlane' && getLiveHyperlaneUrl) {
    const liveHyperlaneUrl = encodeURIComponent(getLiveHyperlaneUrl);
    uri =
      `/hyperlane/redirect/` +
      `?apiRoot=${apiRoot}` +
      `&hyperlaneUrl=${liveHyperlaneUrl}` +
      `&mpId=${mpId}` +
      `&refreshToken=${refreshToken}`;
  }

  return uri;
};

export const mutationObserver = (targetNode, handler) => {
  const config = { attributeFilter: ['class', 'id'] }; // filter the mutations you want to listen
  const callback = function (mutationsList) {
    for (let mutation of mutationsList) {
      handler(mutation.oldValue);
    }
  };
  const observer = new MutationObserver(callback);
  observer.observe(targetNode, config);
};

export const makeSearchTree = (
  categories = [],
  tree = {},
  parentId = null,
  ancestors = []
) => {
  tree.children = [];
  tree.descendants = [tree.categoryId];

  // Up
  categories
    .filter(category => category.parentId === parentId)
    .forEach(category => {
      category.ancestors = [...ancestors, category.categoryId];
      makeSearchTree(
        categories,
        category,
        category.categoryId,
        category.ancestors
      );
      tree.children.push(category);
      if (!tree.descendants.includes(category.categoryId)) {
        tree.descendants.push(category.categoryId);
      }
    });

  // Down
  tree.children.forEach(child => {
    child.descendants.forEach((item, i) => {
      if (!tree.descendants.includes(item)) {
        tree.descendants.push(item);
      }
    });
  });

  return tree;
};

const arrayIncludes = (main, search) => {
  if (search === null) return true; // TODO: - remove

  for (let s = 0; s < search.length; s++) {
    if (main.includes(search[s])) return true;
  }
  return false;
};

export const makeDynamicCategoriesList = (
  tree,
  categoriesWithInventory,
  depth = 1,
  filtered = []
) => {
  const gap = `   `.repeat(depth);

  if (depth === 1) {
    const root = tree;
    if (!find(filtered, { value: root.categoryId }))
      filtered.unshift({
        label: `All Categories`,
        value: root.categoryId,
        depth: 0,
        ...root,
      });
  }

  tree.children.forEach(child => {
    if (arrayIncludes(child.descendants, categoriesWithInventory)) {
      filtered.push({
        label: `${gap}${child.categoryName}`,
        value: child.categoryId,
        depth,
        ...child,
      });
      makeDynamicCategoriesList(
        child,
        categoriesWithInventory,
        depth + 1,
        filtered
      );
    }
  });

  return filtered;
};

export const getCategoriesWithInventory = (buckets = []) => {
  return buckets.map(bucket => Number(bucket.key));
};

export const getAttributeLabelAndValue = (attribute = {}) => {
  const { attributeName, settings, value, typeId } = attribute;

  const displayLabel = attributeName.endsWith(':')
    ? attributeName
    : attributeName + ':';

  let settingsObj = isJSON(settings) ? JSON.parse(settings) : settings;
  settingsObj = isPlainObject(settingsObj) ? settingsObj : {};

  let valueObj = isJSON(value) ? JSON.parse(value) : value;
  valueObj = isPlainObject(valueObj) ? valueObj : {};

  let displayValue = valueObj.value
    ? [2, 3].includes(typeId)
      ? commafy(valueObj.value)
      : valueObj.value
    : valueObj.values
    ? [2, 3].includes(typeId)
      ? valueObj.values.map(val => commafy(valueObj.val)).join(', ')
      : valueObj.values
    : '';

  displayValue = String(displayValue);
  const units = settingsObj.units || '';
  if (displayValue && units) displayValue = `${displayValue} ${units}`;

  return {
    label: displayLabel,
    value: displayValue,
  };
};

export const getFips = ({ state, county }) => {
  const obj = {};

  if (!state || !county) {
    console.error('You must provide a state abbreviation and county name.');
    return obj;
  }
  if (typeof state !== 'string') {
    console.error('State abbreviation must be a string.');
    return obj;
  }
  if (typeof county !== 'string') {
    console.error('County name must be a string.');
    return obj;
  }
  if (state.length !== 2) {
    state = findStateAbbreviation(state);
  }

  state = state.toUpperCase();
  county = county.toUpperCase();
  const fips = require('../constants/fips');
  const match = fips
    .filter(row => row.classfp === 'H1')
    .find(row => {
      const countyname = row.countyname.toUpperCase();
      return (
        (county === countyname || `${county} COUNTY` === countyname) &&
        row.state === state &&
        row.classfp === 'H1'
      );
    });

  return match || obj;
};

export const supressRouterWarning = () => {
  // NOTE - Ignores - Warning: [react-router] You cannot change <Router routes>; it will be ignored
  const isString = val => typeof val === 'string' || val instanceof String;
  const orgError = console.error; // eslint-disable-line no-console
  console.error = (...args) => {
    // eslint-disable-line no-console
    if (
      args &&
      args.length &&
      isString(args[0]) &&
      args[0].includes('You cannot change <Router routes>;')
    ) {
      // React route changed
    } else {
      // Log the error as normally
      orgError.apply(console, args);
    }
  };
};

export const mergeBidHistory = (
  itemId,
  bidHistory = [],
  realtimeBidHistoryData = []
) => {
  if (!itemId) console.error('mergeBidHistory: no itemId', itemId);

  // only interested in a single itemId.
  // must have bidAmount > 0
  // must be bid not offer.
  // remove any duplicate offers.
  // if multiple offers for same amount choose the earliest one.
  // final order is highest bid on top.
  return chain([
    ...bidHistory,
    ...realtimeBidHistoryData.filter(bid => bid.itemId == itemId),
  ])
    .filter(
      bid =>
        bid.bidAmount > 0 && ['Proxy Bid', 'Hard Bid'].includes(bid.bidType)
    )
    .uniqBy('offerId')
    .orderBy('dateCreated', 'asc')
    .uniqBy('bidAmount')
    .orderBy('bidAmount', 'desc')
    .value();
};

export const isZero = n => {
  return parseFloat(n) === 0;
};

// once we decide what we want we can hardcode the values and not use this
export const makeBulkLotBoxShadow = (numberOfLayers = 2, dir = 'down') => {
  const xOffset = 4;
  const yOffset = dir === 'up' ? -3 : 3;
  const blurRadius = 0;
  // const spreadRadius = -0.75;
  const spreadRadius = -0.75;
  const borderThickness = 0.5;

  const card = [xOffset, yOffset, blurRadius, spreadRadius, 'white'];
  const cardBorder = [
    xOffset + borderThickness,
    dir === 'up' ? yOffset - borderThickness : yOffset + borderThickness,
    blurRadius,
    0,
    '#ccc',
  ];
  const pixelfyJoin = (x, i) => (i < 4 ? `${x}px` : x); // adds pixels to lengths

  const makeALayer = layer => {
    const duplicate = (x, i) =>
      i === 0
        ? x + 4.5 * layer
        : i === 1
        ? dir === 'up'
          ? x - 3.5 * layer
          : x + 3.5 * layer
        : x;

    return [
      card.map(duplicate).map(pixelfyJoin).join(' '),
      cardBorder.map(duplicate).map(pixelfyJoin).join(' '),
    ].join(', ');
  };

  // return `
  // 4px 3px 0 -0.75px white,
  // 4.5px 3.5px 0 0 #ccc,

  // 8.5px 6.5px 0 -0.75px white,
  // 9px 7px 0 0 #ccc
  // `;

  return [...Array(numberOfLayers).keys()].map(makeALayer).join(', ');
};

export const transformGetConfig = response => {
  const output = response;
  output.AMSType = response.amsType;
  output.AMSVersion = response.amsVersion;
  output.DefaultSellerAccountNumber = response.defaultSellerAccountNumber;
  delete output.amsType;
  delete output.amsVersion;
  delete output.defaultSellerAccountNumber;
  return output;
};

export const startOver = () => {
  // if a user is trying for a page but is logged out or gets logged out,
  // remember where they were so they can be automatically be directed there upon login

  const postLoginRedirect = window.location.pathname;
  clearAllStorage();

  if (postLoginRedirect && !['/', '/login'].includes(postLoginRedirect)) {
    sessionStorage.setItem('postLoginRedirect', postLoginRedirect);
  }

  if (window.location.pathname !== '/login') {
    window.location.href = '/login';
  }
};

export const makeAssetLink = (filename = '') => {
  if (!filename.includes('.')) {
    console.error('Did you forget the filename extension?', filename);
  }
  const host = 'https://cdn.integratedauctionsolutions.com';
  const bucket = getConfig('bucket');
  return `${host}/${bucket}/${filename}`;
};

export const findDistanceUnits = (marketplaceFeatures = {}) => {
  let units = '';
  if (marketplaceFeatures.features) {
    const features = (marketplaceFeatures.features || '').split(',');
    units = features.includes('389') ? 'km' : 'mi';
  }
  return units;
};

export const hasMPFeature = (features = '', feature) => {
  return features.split(',').some(f => f === String(feature));
};

export const makeCompanyCopyright = () => {
  return `© ${moment().format('YYYY')} Superior Integrated Auctions, LLC`;
};

export const checkLink = async link => {
  if (!link) {
    return false;
  }

  try {
    const response = await fetch(link, { method: 'HEAD' });

    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export {transportRequests} from './transportRequests';